<template>
  <div>
    <topmenu :comname="'dynamic'"></topmenu>
    <div class="widht1240">
      <div class="t-map">您当前的位置：<span>大会动态</span></div>
      <div class="widht200 fl">
        <contact></contact>
      </div>
      <div class="widht1030 bg-white fr">
        <div class="pa30">
          <div class="title">{{ vName }}</div>
          <div class="time">
            发布时间：{{ time | formatDate }}
            <span class="pl10">来源：{{ source }}</span>
            <span class="pl10">关键字：{{ nvcKeyword }}</span>
            <el-dropdown @command="handleCommand" style="float: right">
              <span class="el-dropdown-link">
                <i class="el-icon-share"></i>
                我要分享<i class="el-icon-arrow-down el-icon--right"></i>
              </span>
              <el-dropdown-menu slot="dropdown">
                <el-dropdown-item command="a"
                  ><i class="iconfont icon-weixin weixin"></i
                  ><span>微信</span></el-dropdown-item
                >
                <el-dropdown-item command="b"
                  ><i class="iconfont icon-sinaweibo sinaweibo"></i
                  ><span>新浪微博</span></el-dropdown-item
                >
                <el-dropdown-item command="c"
                  ><i class="iconfont icon-QQ QQ"></i
                  ><span>QQ</span></el-dropdown-item
                >
                <el-dropdown-item command="d"
                  ><i class="iconfont icon-kongjian kongjian"></i
                  ><span>QQ空间</span></el-dropdown-item
                >
              </el-dropdown-menu>
            </el-dropdown>
          </div>
          <div v-if="videoUrl" style="margin-top:20px">
            <video controls  :src="videoUrl"  height="500" style="max-width: 100%;"></video>
          </div>
          <div class="zhaiyao" v-if="vAbstract != null && vAbstract != ''">
            {{ vAbstract }}
          </div>
          <div class="content" v-html="vContent"></div>
        </div>
      </div>
    </div>
    <bottominfo></bottominfo>
  </div>
</template>
<script>
import topmenu from "@/components/public/top";
import bottominfo from "@/components/public/bottom";
import contact from "@/components/public/contact";
export default {
  name: "dynamicdetails",
  data() {
    return {
      id: "",
      vName: "",
      time: "",
      vAbstract: "",
      vContent: "",
      source: "",
      nvcKeyword: "",
      videoUrl:'',   //视频地址
    };
  },
  components: {
    topmenu,
    bottominfo,
    contact,
  },
  created() {
    if (this.$route.query.id) {
      this.id = this.$route.query.id;
    }
    this.getinformation();
  },
  watch: {
    $route: function (to, from) {
      this.id = this.$route.query.id;
      this.getinformation();
    },
  },
  methods: {
    handleCommand(command) {
      // this.$message("click on item " + command);
      if (command == "a") {
        this.weixin(this.vName);
      } else if (command == "b") {
        this.sinaWeiBo(this.vName);
      } else if (command == "c") {
        this.qqFriend(this.vName);
      } else if (command == "d") {
        this.qqZone(this.vName);
      }
    },
    //qq好友分享
    qqFriend(title) {
      var p = {
        /*获取URL，可加上来自分享到QQ标识，方便统计*/
        url: window.location.href,
        desc: "",
        /*分享标题(可选)*/
        title: title,
        /*分享摘要(可选)*/
        summary: "",
        /*分享图片(可选)*/
        pics: "",
        /*视频地址(可选)*/
        flash: "",
        /*分享来源(可选) 如：QQ分享*/
        site: "",
        style: "201",
        width: 32,
        height: 32,
      };
      var s = [];
      for (var i in p) {
        s.push(i + "=" + encodeURIComponent(p[i] || ""));
      }
      var url =
        "http://connect.qq.com/widget/shareqq/index.html?" + s.join("&");
      window.open(url);
    },

    //qq空间
    qqZone(title) {
      var p = {
        url: window.location.href,
        showcount: "0" /*是否显示分享总数,显示：'1'，不显示：'0' */,
        desc: "" /*默认分享理由(可选)*/,
        summary: "" /*分享摘要(可选)*/,
        title: title + "【易应急】" /*分享标题(可选)*/,
        site: "" /*分享来源 如：腾讯网(可选)*/,
        pics: "",
        style: "203",
        width: 98,
        height: 22,
      };
      var s = [];
      for (var i in p) {
        s.push(i + "=" + encodeURIComponent(p[i] || ""));
      }
      var url =
        "http://sns.qzone.qq.com/cgi-bin/qzshare/cgi_qzshare_onekey?" +
        s.join("&");
      window.open(url);
    },

    //新浪微博
    sinaWeiBo(title) {
      var p = {
        // 表示是否显示当前页面被分享数量(1 显示)(可选， 允许为空)
        count: "",
        //将页面地址转成短域名， 并显示在内容文字后面。(可选， 允许为空)
        url: window.location.href,
        //用于发布微博的来源显示， 为空则分享的内容来源会显示来自互联网。(可选， 允许为空)
        appkey: "",
        //分享时所示的文字内容， 为空则自动抓取分享页面的title值(可选， 允许为空)
        title: title,
        //自定义图片地址， 作为微博配图(可选， 允许为空)
        pic: "",
        //转发时会 @相关的微博账号(可选， 允许为空)
        ralateUid: "",
        //语言设置(zh_cn | zh_tw)(可选)
        language: "zh_cn",
      };

      var s = [];
      for (var i in p) {
        s.push(i + "=" + encodeURIComponent(p[i] || ""));
      }
      var url = "http://service.weibo.com/share/share.php?" + s.join("&");
      window.open(url);
    },

    weixin() {
      var url = window.location.href,
        encodePath = encodeURIComponent(url),
        // targetUrl = 'http://qr.liantu.com/api.php?text=' + encodePath;
        targetUrl = "https://api.pwmqr.com/qrcode/create/?url=" + encodePath;
      window.open(targetUrl, "weixin", "height=352, width=351");
    },
    getinformation() {
      let data = {
        id: Number(this.id),
      };
      this.$axios
        .post("/api/app/exhibitionDynamic/getExhibitionDynamicById", data)
        .then((res) => {
          if (res[0].length > 0) {
            let infordata = res[0];
            this.videoUrl = infordata[0].videoUrl;
            this.vName = infordata[0].vName;
            this.time = infordata[0].dCreateTime;
            this.vAbstract = infordata[0].vAbstract;
            this.vContent = infordata[0].vContent;
            this.source = infordata[0].nvcNewsSource;
            this.nvcKeyword = infordata[0].nvcKeywords;
          }
        });
    },
  },
};
</script>
<style scoped>
.pa30 {
  padding: 30px;
}
.title {
  font-size: 24px;
  line-height: 60px;
  color: #000;
}
.time {
  color: #666;
  line-height: 30px;
}
.content {
  line-height: 28px;
  color: #333;
  text-align: left;
  margin-top: 20px;
}
.zhaiyao {
  background-color: #f8f8f8;
  padding: 20px;
  color: #333;
  text-indent: 2em;
  text-align: left;
  margin-top: 20px;
}
.pl10 {
  padding-left: 20px;
}
.el-dropdown {
  vertical-align: top;
}
.el-dropdown + .el-dropdown {
  margin-left: 15px;
}
.el-icon-arrow-down {
  font-size: 12px;
}
.el-dropdown-link {
  cursor: pointer;
  color: #409eff;
}
.el-icon-arrow-down {
  font-size: 12px;
}
.el-dropdown-menu__item {
  padding: 0 10px;
}
.iconfont {
  border-radius: 50%;
  padding: 5px;
}
.weixin {
  color: #fff;
  background: #27cc2c;
}
.sinaweibo {
  color: #fff;
  background: #df4d69;
}
.QQ {
  color: #fff;
  background: #0596f8;
}
.kongjian {
  color: #fff;
  background: #ffc201;
}
</style>